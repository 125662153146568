<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap5FirstGoApplication"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 5: First Go Application" image-name="5-first-go-app.jpg" image-alt="First Go Application"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Write a simple program with Go</p></li>
<li><p>Compile a program</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h2 data-number="1.1"><span class="header-section-number">1.1</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Specifications</p></li>
<li><p>Compilation</p></li>
<li><p>A binary / an executable</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In this chapter, we will write our two first Go applications.</p>
<div id="application-specification" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Application specification <a href="#application-specification"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Before writing any code, we need to decide what our application will do. Most projects begin with this phase. This is called the specification phase. It aims to produce precise requirements that the application should fulfill. Those requirements are the specifications (or the specs).</p>
<p>Our application specification is simple: when launched, the application should display the date and time, and exit.</p>
<div id="project-directory" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Project directory <a href="#project-directory"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A Go application is composed of files. On those files, we will write Go code. We call those file “<strong>source files</strong>”. An application is stored in the main directory. This main directory can contain just one source file. Most of the time, it’s composed of several sub-directories.</p>
<p>We will create this main directory for our application. You can do it using the command line (or with the graphical interface of your system) :</p>
<pre v-highlightjs><code class="go" v-pre >$ cd Documents/code
$ mkdir dateAndTime</code></pre>
<div id="ide" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> IDE <a href="#ide"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We are just minutes away from writing code. Do you need special software to write Go code? In theory, you can write code with a standard text editor. There is dedicated software on the market specially developed for developers. They are called IDE: Integrated Development Environment.</p>
<p>IDE provides functionalities like :</p>
<ul>
<li><p>Automatic coloration for reserved words (syntax highlighting)</p></li>
<li><p>Autocompletion</p></li>
<li><p>Refactoring capabilities</p></li>
<li><p>...</p></li>
</ul>
<p>They are many IDE on the market. You can search google to find the best fit for you. I use Goland, which IntelliJ develops. This software is not free (it’s subscription-based), but I find it easy to use.</p>
<div id="source-file" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Source file <a href="#source-file"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s create our source file. We will name it <strong>main.go</strong>.</p>
<pre v-highlightjs><code class="go" v-pre >// first-go-application/first/main.go
package main

import (
    &quot;fmt&quot;
    &quot;time&quot;
)

func main() {
    now := time.Now()
    fmt.Println(now)
}</code></pre>
<div id="code-explanation" class="anchor"></div>
<h4 data-number="6.0.0.1"><span class="header-section-number">6.0.0.1</span> Code explanation <a href="#code-explanation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>The first line is mandatory in Go. In all files, you must add the <strong>package</strong> declaration. Such declaration is composed of the keyword <strong>package</strong> then the name of the package.</p></li>
<li><p>On the second line, you can read another keyword: <strong>import.</strong> It is usually followed by an open parenthesis and a list of the program’s imported packages. Every package is written on a new line. Each package has a name delimited by double-quotes. Here our application depends on two packages :</p>
<ul>
<li><p>fmt</p></li>
<li><p>time</p></li>
</ul></li>
<li><p>Those packages are part of the <strong>standard library.</strong></p></li>
<li><p>Then you find the declaration of a function named <strong>main</strong>. We will go deeper into the syntax of functions later.</p>
<ul>
<li><p>The function declaration is enclosed with curly brackets : <strong>{</strong> and <strong>}</strong>.</p></li>
<li><p>Inside the function declaration, we have two statements :</p>
<ul>
<li><p>The first instruction is an affectation. We initialize the variable “<strong>now</strong>” and we give it the value returned by the function call <strong>Now()</strong>from the package <strong>time.</strong></p></li>
<li><p>The second instruction is a call of the function <strong>Print</strong> from the package <strong>fmt</strong></p></li>
</ul></li>
</ul></li>
<li><p>WARNING! be sure that you only use packages that you actually import. Otherwise your program will not compile... When you use a package not imported, your Go program will not compile</p></li>
<li><p>Make sure that you use imported packages. In the following code sample I import the package <span v-highlightjs><code class="go" v-pre style="display: inline">fmt</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">time</code></span> but I do not use them in my <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// DO NOT COMPILE
// first-go-application/import-issue/main.go
package main

import (
    &quot;fmt&quot;
    &quot;time&quot;
)

func main(){

}</code></pre>
<div id="about-the-main-function" class="anchor"></div>
<h4 data-number="6.0.0.2"><span class="header-section-number">6.0.0.2</span> About the main function <a href="#about-the-main-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The main function is the <strong><u>entry point</u></strong> of the program. In every application, you have at least one main function. The program will start with the first statement of this function. (Note that in C, C++, Java, the main function’s concept exists).</p>
<div id="compilation" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Compilation <a href="#compilation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The source file is ready to be transformed into a binary (or executable). To do so, we will use the Go toolchain. Open a terminal :</p>
<pre v-highlightjs><code class="go" v-pre >$ cd Documents/code/dateAndTime
$ go build main.go</code></pre>
<p>The first instruction (cd) instructs the shell to change the current directory to <strong>Documents/code/dateAndTime</strong>. This command will compile the program into an executable. The executable is named main (the same name as the source file, without the .go extension). Let’s see the files that are now into our dateAndTime directory :</p>
<pre v-highlightjs><code class="go" v-pre >$ ls -lh
total 4160
-rwxr-xr-x  1 maximilienandile  staff   2.0M Aug 16 11:27 main
-rw-r--r--  1 maximilienandile  staff    94B Aug 16 11:00 main.go</code></pre>
<p>We use the command <strong>ls</strong>. (for windows user, you can use the command <strong>dir</strong>). You can see that we have two files :</p>
<ul>
<li><p><strong>main</strong> that weights 2.0M (MegaBytes), and that is executable.</p></li>
<li><p><strong>main.go</strong> that weights only 94 Bytes. (this is our source file).</p></li>
</ul>
<p>Now it’s time to launch our application :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./main
2019-08-16 11:45:44.435637 +0200 CEST m=+0.000263533</code></pre>
<p>Congratulations on your first Go application!</p>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to compile a Go application?</p></li>
<li><p>How is the result of the compilation called?</p></li>
<li><p>What is the name of the entry point function of a Go application?</p></li>
<li><p>What is the usage of the import statement?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to compile a Go application?</p>
<ol type="1">
<li><p>Open a terminal</p></li>
<li><p>Go to the directory of your application. Providing that there is a file named main.go that contains a main function issue those commands :</p>
<ol type="1">
<li><p>$ cd /code/myApp</p></li>
<li><p>$ go build main.go</p></li>
</ol></li>
</ol></li>
<li><p>How is the result of the compilation called?</p>
<ol type="1">
<li>It’s called an executable or binary</li>
</ol></li>
<li><p>What is the name of the entry point function of a Go application?</p>
<ol type="1">
<li>main</li>
</ol></li>
<li><p>What is the usage of the import statement?</p>
<ol type="1">
<li><p>It’s used to import packages (from the standard library or other sources) into the program.</p></li>
<li><p>An imported package can then be used inside the code.</p></li>
</ol></li>
</ol>
<div id="exercise" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Exercise <a href="#exercise"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="specifications" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Specifications <a href="#specifications"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div class="list">
<p>Create a go application that displays the string “Hello World” on the screen and exit.</p>
</div>
<div id="corrections" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Corrections <a href="#corrections"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div class="list">

</div>
<pre v-highlightjs><code class="go" v-pre >// first-go-application/hello-world/main.go
package main

import &quot;fmt&quot;

func main() {
    fmt.Println(&quot;Hello World&quot;)
}</code></pre>
<p>Notes :</p>
<ul>
<li><p>Here you can see that we use just one dependency. The import part of the program has changed; parenthesis is not required when importing only one package.</p></li>
<li><p>We have a main function. And a single statement inside.</p></li>
<li><p>Here the function Println is called. This function is part of the package fmt (fmt stands for “formatting”).</p></li>
<li><p>The package fmt is part of the standard library.</p></li>
</ul>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>To create a simple program</p>
<ul>
<li><p>Create a file</p></li>
<li><p>Name it <strong>main.go</strong></p></li>
<li><p>Here is the basic skeleton of a program (that does nothing)</p></li>
</ul></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// first-go-application/skeleton/main.go
package main

func main() {
    
}</code></pre>
<ul>
<li><p>This file is denoted “source file”</p></li>
<li><p>From this source file, we can create an executable program (that can be launched).</p></li>
<li><p>The creation of the executable is called “compilation”.</p></li>
<li><p>To compile a program, type the following command into the terminal</p>
<ul>
<li>go build main.go</li>
</ul></li>
<li><p>To launch the compiled program, type the following into your terminal :</p>
<ul>
<li>./main</li>
</ul></li>
</ul>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap5FirstGoApplication"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap4SetupYourDevEnvironment'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Setup your dev environment</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap6BinaryAndDecimal'}">
									<p><u><small>Next</small></u></p>
									<p><small>Binary and Decimal</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "First Go Application - Practical Go Lessons"
const description = "In this chapter, we will write our two first Go applications."

export default {
  name: "Chap5FirstGoApplication",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
